import { Arr, Constants as C, Is } from "core";
import FilePicker = require("Everlaw/FilePicker");
import Rest = require("Everlaw/Rest");
import Util = require("Everlaw/Util");
import { notify } from "Everlaw/Bugsnag";
import { S3Source } from "Everlaw/Model/Upload/S3Source";

export interface MultipartUploadParams {
    file: FilePicker.FileLike;
    source: S3Source;
    progress: () => void;
    error: (aborted: boolean) => void;
    success: () => void;
}

export interface UploadPart {
    partNumber: number; // 1-indexed part number
    size: number;
    completed: number;
    eTag?: string;
    // Not sent from the backend:
    offset?: number;
    tryCount?: number;
}

/**
 * Uploads files to S3 using server-signed part urls.
 */
export class MultipartUpload {
    static ongoing: { [uploadId: string]: MultipartUpload } = {};
    private static keepAliveHandle: number | null = null;
    private static readonly RATE_SAMPLE_COUNT = 50;
    private static numRetries = 5;
    private static concurrency = 4;
    private static minPartSize = 100 * C.MB;
    private static readonly MAX_PARTS = 10000;
    protected file: FilePicker.FileLike;
    source: S3Source;
    private progress: () => void;
    private error: (aborted: boolean) => void;
    private success: () => void;
    protected lastPart = -1;
    protected partInfo: UploadPart[] = [];
    protected started = false;
    private ongoingParts: Map<number, XMLHttpRequest> = new Map();
    private worker: Worker;
    private completed = false;
    private aborted = false;
    private rateSamples: number[] = [];
    private rateIdx = 0;
    private lastSampled: number;
    private lastSampleCompleted: number;
    protected sizeLoaded = false;
    constructor(params: MultipartUploadParams) {
        Object.assign(this, params);
    }

    private static addOngoing(upload: MultipartUpload): void {
        MultipartUpload.ongoing[upload.source.s3UploadId] = upload;
        if (this.keepAliveHandle === null) {
            this.keepAliveHandle = Rest.startKeepAliveInterval();
        }
    }

    private static removeOngoing(s3UploadId: string): void {
        delete MultipartUpload.ongoing[s3UploadId];
        if (numOngoing() === 0 && this.keepAliveHandle !== null) {
            clearInterval(this.keepAliveHandle);
            this.keepAliveHandle = null;
        }
    }

    /**
     * We determine the part size dynamically, since we won't know the actual size of a directory
     * until the traversal finishes. Our default size (10MB) is good for files up to 100GB (based on
     * the limit of 10,000 parts per upload), but for larger files we'll need to start scaling up
     * the part size once we know it's bigger, and we'll have to do so based on how many parts are
     * left and how much data is left.
     */
    private guessPartSize(): number {
        let remainingSize = this.file.size;
        let remainingParts = MultipartUpload.MAX_PARTS;
        if (this.lastPart > 0) {
            const last = this.partInfo[this.lastPart - 1];
            remainingSize -= last.offset + last.size;
            remainingParts -= this.lastPart + 1;
        }
        return Math.max(MultipartUpload.minPartSize, Math.ceil(remainingSize / remainingParts));
    }

    protected loadExistingParts(parts: UploadPart[]): void {
        parts = Arr.sort(parts, { key: (p) => p.partNumber });
        for (let i = 0; i < parts.length; i++) {
            // Set all sequential part info.
            if (parts[i].partNumber === i + 1 && parts[i].eTag) {
                // partNumber is 1-indexed
                this.partInfo[i] = parts[i];
                if (i > 0) {
                    this.partInfo[i].offset = parts[i - 1].offset + parts[i - 1].size;
                } else {
                    this.partInfo[i].offset = 0;
                }
                this.partInfo[i].completed = parts[i].size;
                this.lastPart = i;
            } else {
                break;
            }
        }
        if (this.lastPart >= 0) {
            const last = this.partInfo[this.lastPart];
            this.file.fastForward(last.offset + last.size);
        }
    }

    private completedSize(): number {
        return this.partInfo.reduce((a, b) => a + b.completed, 0);
    }

    start(): void {
        !this.started && this.startUploading();
        this.started = true;
    }

    protected startUploading(): void {
        MultipartUpload.addOngoing(this);
        // Update with any pre-sent parts
        this.updateProgress();
        this.lastSampled = Date.now();
        this.lastSampleCompleted = this.completedSize();
        for (let i = 0; i < MultipartUpload.concurrency; i++) {
            this.maybeSendNext();
        }
        // When resuming upload, it's possible that all parts were actually finished but the upload
        // was never completed.
        this.checkAllFinished();
    }

    abort(userAbort = true): void {
        if (!userAbort) {
            notify(new Error(`Aborted upload for source ${this.source.id}`));
        }
        this.aborted = true;
        this.abortFileTask(userAbort);
        this.ongoingParts.forEach((xhr) => {
            // In Chrome, as of version 37, sometimes one of these events (at least onprogress) can
            // fire after calling abort(). Make sure our callbacks don't get called in this case.
            xhr.upload.onprogress = null;
            xhr.onreadystatechange = null;
            xhr.abort();
        });
        MultipartUpload.removeOngoing(this.source.s3UploadId);
        this.error(userAbort);
    }

    abortFileTask(userAbort: boolean): void {
        this.file.abort(!userAbort);
    }

    private maybeSendNext(): void {
        if (this.running()) {
            if (this.file.isError()) {
                this.abort(false);
            } else {
                if (this.lastPart < 0) {
                    // Send the first part
                    this.sendPart(++this.lastPart);
                } else {
                    const last = this.partInfo[this.lastPart];
                    // If we haven't yet finished loading the size, try the next part (but it may
                    // turn out to be empty!)
                    if (last.offset + last.size < this.file.size || !this.file.sizeIsLoaded()) {
                        this.sendPart(++this.lastPart);
                    }
                }
            }
        }
    }

    private updateProgress(): void {
        if (this.running()) {
            let currRate = this.currentRate();
            if (!Is.number(currRate) && this.source.progress) {
                currRate = this.source.progress.rate;
            }
            this.source.progress = {
                completed: this.completedSize(),
                rate: currRate,
            };
            this.checkSize();
            this.progress();
        }
    }

    protected checkSize(): void {
        // Once we've loaded the size, we don't need to call `setSize` again.
        if (!this.sizeLoaded) {
            if (this.file.sizeIsLoaded()) {
                this.sizeLoaded = true;
            }
            this.source.length = this.file.size;
        }
    }

    private running(): boolean {
        return !(this.aborted || this.completed);
    }

    currentRate(): number {
        return this.rateSamples.length === 0
            ? null
            : this.rateSamples.reduce((a, b) => a + b) / this.rateSamples.length;
    }

    protected isAllFinished(): boolean {
        return (
            this.running()
            && this.file.sizeIsLoaded()
            && this.completedSize() === this.file.size
            && this.partInfo.every((p) => p && (!!p.eTag || p.size === 0))
        );
    }

    protected checkAllFinished(): void {
        // Check our completed flag (to make sure we only make the signComplete.rest request once),
        // and see if all the parts are done. Don't wait for the hash, though.
        if (this.isAllFinished()) {
            this.checkSize();
            this.completed = true;
            if (this.worker) {
                this.worker.terminate();
                this.worker = null;
            }
            MultipartUpload.removeOngoing(this.source.s3UploadId);
            this.source
                .completeUpload(this.partInfo.filter((pi) => pi.size !== 0).map((p) => p.eTag))
                .then(
                    () => this.success(),
                    () => this.abort(false),
                );
        }
    }

    private complete(part: number, etag: string): void {
        this.ongoingParts.delete(part);
        const partInfo = this.partInfo[part];
        partInfo.completed = partInfo.size;
        partInfo.eTag = etag;
        if (this.partInfo.every((pi) => !!pi.eTag || pi.partNumber > part)) {
            // If this completed part was the earliest ongoing part, we can fast-forward to its end.
            this.file.fastForward(partInfo.offset + partInfo.size);
        }
        this.updateProgress();
        this.maybeSendNext();
        this.checkAllFinished();
    }

    private sendPart(part: number, authRetry = false): void {
        if (!this.running()) {
            return;
        }
        if (!(part in this.partInfo)) {
            let offset: number;
            if (part === 0) {
                offset = 0;
            } else {
                const prevPart = this.partInfo[part - 1];
                offset = prevPart.offset + prevPart.size;
            }
            this.partInfo[part] = {
                partNumber: part + 1, // S3 Part numbers are 1-indexed
                size: this.guessPartSize(),
                offset: offset,
                completed: 0,
                tryCount: 0,
            };
        }
        const partInfo = this.partInfo[part];
        if (partInfo.tryCount >= MultipartUpload.numRetries || this.file.isError()) {
            this.abort(false);
        } else {
            // Send this part!
            if (!authRetry) {
                // Only count the retry if we didn't just fail due to authentication issues.
                partInfo.tryCount++;
            }
            partInfo.completed = 0;
            this.updateProgress();
            this.source.signPart(partInfo.partNumber).then(
                (url) => this.send(part, partInfo, url),
                (e) => {
                    // Try again after a short wait for normal errors, and after a minute for
                    // auth-related failures.
                    const authFailure = Rest.isAuthFailure(e);
                    this.retry(part, authFailure ? C.MIN : partInfo.tryCount * 2000, authFailure);
                },
            );
        }
    }

    private partProgress(part: number, ev: ProgressEvent): void {
        if (ev.lengthComputable) {
            const partInfo = this.partInfo[part];
            this.updateProgress();
            const now = Date.now();
            const timeDiff = now - this.lastSampled;
            partInfo.completed = ev.loaded;
            if (timeDiff > 2 * C.SEC) {
                const newSize = this.completedSize();
                const sizeDiff = newSize - this.lastSampleCompleted;
                const rateSample = sizeDiff / timeDiff;
                if (this.rateSamples.length < MultipartUpload.RATE_SAMPLE_COUNT) {
                    this.rateSamples.push(rateSample);
                } else {
                    this.rateSamples[this.rateIdx] = rateSample;
                    this.rateIdx = (this.rateIdx + 1) % this.rateSamples.length;
                }
                this.lastSampled = now;
                this.lastSampleCompleted = newSize;
            }
        }
    }

    private retry(part: number, timeout: number, authError = false): void {
        notify(new Error(`Retrying part number ${part} in source ${this.source.id}`), {
            severity: "info",
        });
        setTimeout(() => {
            this.sendPart(part, authError);
        }, timeout);
    }

    private send(part: number, partInfo: UploadPart, url: string): void {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.upload.onprogress = (ev) => {
            this.partProgress(part, ev);
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (
                    xhr.status === 200
                    && xhr.getResponseHeader("ETag")
                    && partInfo.completed === partInfo.size
                ) {
                    this.complete(part, xhr.getResponseHeader("ETag"));
                } else if (this.running()) {
                    // Try again after a short wait.
                    this.retry(part, partInfo.tryCount * 2000);
                }
            }
        };
        this.file
            .slice(partInfo.offset, partInfo.offset + partInfo.size)
            .then((blob) => {
                if (blob) {
                    // We read something - check that it was the right length.
                    // It's possible that if the size was not loaded but this turned out to the be last
                    // part, it's smaller than expected. If so, update our part size.
                    if (
                        this.file.size === partInfo.offset + blob.size
                        && this.file.sizeIsLoaded()
                    ) {
                        partInfo.size = blob.size;
                    }
                    if (blob.size === partInfo.size) {
                        xhr.send(blob);
                        this.ongoingParts.set(part, xhr);
                    } else {
                        // The part size did not match: this is an error.
                        return Promise.reject();
                    }
                } else {
                    // We didn't read anything - it's possible we were already at the end of the file.
                    if (this.file.sizeIsLoaded() && partInfo.offset >= this.file.size) {
                        // If we didn't get any output at all, it's possible we didn't know the total
                        // file size but it happened that we had already seen it all. In this case, we're
                        // done! Mark the part as being empty and ignorable.
                        partInfo.size = 0;
                        this.updateProgress();
                        this.checkAllFinished();
                    } else {
                        // Otherwise, this is an error.
                        return Promise.reject();
                    }
                }
            })
            .catch(() => {
                this.retry(part, 5000);
            });
    }
}

export function numOngoing(): number {
    return Object.entries(MultipartUpload.ongoing).length;
}

export function stop(source: S3Source): void {
    if (source.s3UploadId in MultipartUpload.ongoing) {
        MultipartUpload.ongoing[source.s3UploadId].abort();
    }
}

Util.warnOnUnload(numOngoing, "upload");
