import * as Base from "Everlaw/Base";
import { Compare as Cmp } from "core";
import * as MultipartUpload from "Everlaw/Model/Upload/MultipartUpload";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import {
    DocumentS3Source,
    DocumentS3SourceRestData,
    setDirInfo,
} from "Everlaw/Model/Upload/DocumentS3Source";
import * as UploadSource from "Everlaw/Model/Upload/UploadSource";
import { SourceState } from "Everlaw/Model/Upload/S3Source";
import { Id } from "Everlaw/User";

export interface ProcessedSourceRestData extends DocumentS3SourceRestData {
    databaseId: number;
    initialProjectId: number;
    displayName: string;
    created: number;
    message: string;
    preProduction: boolean;
    uploadIds?: number[];
    uploadCount?: number;
}

/**
 * Contains upload config and S3 source file information for a file uploaded through File Transfers.
 * A front-end correspondence of ProcessedSource.java.
 */
export class ProcessedSource extends DocumentS3Source {
    get className(): string {
        return "ProcessedSource";
    }
    databaseId: number;
    initialProjectId: number;
    displayName: string;
    created: number;
    message: string;
    preProduction: boolean;
    uploadIds: number[];
    uploadCount: number;

    constructor(data: ProcessedSourceRestData) {
        super(data);
    }

    override _mixin(params: any): void {
        this.uploadIds = [];
        super._mixin(params);
    }

    getUserId(): Id {
        return this.userId;
    }

    getCreated(): number {
        return this.created;
    }

    override compare(other: ProcessedSource): number {
        // sort by created, breaking ties by id
        return Cmp.num(other.created, this.created) || Cmp.num(this.id, other.id);
    }

    getMessage(): string {
        return this.message;
    }

    setMessage(newMessage: string): void {
        this.update(this.displayName, newMessage);
    }

    rename(newName: string): Promise<string> {
        return this.update(newName, this.message).then((res) => res.displayName);
    }

    update(newName: string, newMessage: string): Promise<ProcessedSource> {
        if (newName !== this.displayName || newMessage !== this.message) {
            return Rest.post("processing/updateProcessedInfo.rest", {
                sourceId: this.id,
                name: newName,
                message: newMessage,
            }).then((data) => {
                this.displayName = data.displayName;
                this.message = newMessage;
                Base.publish(this);
                return this;
            });
        } else {
            return Promise.resolve(this);
        }
    }

    remove(): void {
        Rest.post("processing/deleteProcessedUpload.rest", { sourceId: this.id }).then(() =>
            Base.remove(this),
        );
    }

    /**
     * Like remove() but first stops any currently running uploads
     */
    async cancelUploadAndRemove(): Promise<void> {
        if (this.state === SourceState.UPLOADING) {
            if (UploadSource.isLocal(this.uploadSource)) {
                MultipartUpload.stop(this);
            } else {
                await Rest.post("tasks/abortUploadTask.rest", {
                    requestId: this.uploadTaskId,
                });
            }
        }

        await Rest.post("processing/deleteProcessedUpload.rest", { sourceId: this.id });

        Base.remove(this);
    }

    downloadUrl(): string {
        return Project.CURRENT.url("processing/downloadProcessedSource.do?sourceId=" + this.id);
    }

    override display(): string {
        return this.displayName || this.filename;
    }

    setState(newState: SourceState): void {
        Rest.post("processing/updateProcessedState.rest", {
            sourceId: this.id,
            state: SourceState[newState],
        }).then(() => {
            this.state = newState;
            Base.publish(this);
        });
    }

    getSize(): number {
        return this.length;
    }

    completeUpload(etags: string[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Rest.post("processing/completeProcessedUpload.rest", {
                sourceId: this.id,
                etags,
            }).then(
                () => {
                    this.state = SourceState.UPLOADED;
                    Base.publish(this);
                    resolve();
                },
                (e) => {
                    reject(e);
                    throw e;
                },
            );
        });
    }

    displayStatus(): string {
        switch (this.state) {
            case SourceState.UPLOADING:
                if (this.isQueued()) {
                    return "Queued";
                } else if (this.lastActive && this.isError()) {
                    return "Error";
                }
                return "Uploading";
            case SourceState.UPLOADED:
                return "Received";
            case SourceState.PENDING:
                return "Pending";
            case SourceState.PROCESSING:
                return "Processing";
            case SourceState.WILL_NOT_COMPLETE:
                return "Not to be completed";
            case SourceState.COMPLETED:
                return "Completed";
            default:
                return "";
        }
    }

    setDirInfo(): void {
        setDirInfo(this, true);
    }
}
