import { Argument, getArgumentURL } from "Everlaw/Argument";
import Assignment = require("Everlaw/Assignment");
import AssignmentGroup = require("Everlaw/AssignmentGroup");
import Base = require("Everlaw/Base");
import Binder = require("Everlaw/Binder");
import Chronology = require("Everlaw/Chron/Chronology");
import { PubSubChannel } from "Everlaw/PubSubChannel";
import { Arr, Is, Str } from "core";
import Deposition = require("Everlaw/Depo/Deposition");
import DepoPageDefs = require("Everlaw/Depo/DepositionPageDefs");
import Document = require("Everlaw/Document");
import Dom = require("Everlaw/Dom");
import Eql = require("Everlaw/Eql");
import HomepageFolder = require("Everlaw/HomepageFolder");
import Perm = require("Everlaw/PermissionStrings");
import PredictionModel = require("Everlaw/PredictionModel");
import Dataset = require("Everlaw/Model/Processing/ProcessingDataset");
import Production = require("Everlaw/Model/Production/Production");
import Project = require("Everlaw/Project");
import Property = require("Everlaw/Property");
import { Recipient } from "Everlaw/Recipient";
import Rest = require("Everlaw/Rest");
import SavedReviewLayout = require("Everlaw/Review/SavedReviewLayout");
import SavedResultsTableView = require("Everlaw/SavedResultsTableView");
import SearchResult = require("Everlaw/SearchResult");
import SearchTermReport = require("Everlaw/SearchTermReport");
import Security = require("Everlaw/Security");
import SbPageDefs = require("Everlaw/Storybuilder/StorybuilderPageDefs");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import ToggledSingleSelect = require("Everlaw/UI/ToggledSingleSelect");
import Upload = require("Everlaw/Model/Upload/Upload");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import { WritingAssistantTemplate } from "Everlaw/Argument/View/WritingAssistantDialog/util/WritingAssistantTemplate";
import { MinimalOrganization } from "Everlaw/MinimalOrganization";
import { ProcessedSource } from "Everlaw/Model/Processing/ProcessedSource";
import { resultsURL } from "Everlaw/Util/ResultsUrlUtil";

/** Returns the Recipient EBO corresponding to the given sid string. */
export function toRecipientEbo(sid: string): Recipient {
    if (Str.startsWith(sid, "GROUP_")) {
        return Base.get(User.Group, sid.slice(6));
    }
    if (Str.startsWith(sid, "ORG_")) {
        return Base.get(MinimalOrganization, sid.slice(4));
    }
    // Assume it's either a role or a user id.
    return Base.get(Security.ProjectRolePrimitive, sid) || Base.get(User, sid);
}

/** Returns the display string for the given sid string. */
export function displaySid(sid: string): string {
    const obj = toRecipientEbo(sid);
    let display = `[Unknown user: ${sid}]`;
    if (obj) {
        if (obj instanceof User) {
            display = obj.displayWithAbbrev();
        } else if (obj instanceof Security.ProjectRolePrimitive) {
            display = `[${obj.display()}]`;
        } else {
            display = obj.display();
        }
    }
    return display;
}

/** Class used for sharing Presets. */
export class CodingPreset extends Base.Object {
    get className() {
        return "CodingPreset";
    }
    override id: number;
    name: string;
    mutator: any;
    canApply: boolean;
    constructor(params: any) {
        super(params);
        Object.assign(this, params);
    }
    override display(): string {
        return this.name || "Preset " + (this.id + 1);
    }
}

/**
 * Wrapper around a Base.DataPrimitive for storing the permission string(s) associated with a
 * specific permission (id).
 */
export class SecurityInfo extends Base.DataPrimitive<Base.PermList> {
    constructor(
        public override id: string,
        perms: Base.PermList,
        public fullAccess = false,
    ) {
        super(perms, id);
    }
    permString(): string {
        return Str.capitalize(Str.arrayToStringList(this.data));
    }
    override display(): string {
        return this.fullAccess ? "Full access" : this.permString();
    }
}

/**
 * Wrapper class for storing an array of SecurityInfo. The real need for this is the
 * fullPermString() method, which creates a string of permissions for all elements less than or
 * equal to a given index (in order to make the hierarchy clear).
 */
export class SecurityMap {
    constructor(
        public elems: SecurityInfo[],
        public projectAdminAccess = true,
    ) {}
    /** Returns the string of permissions corresponding to the given index. */
    permString(index: number): string {
        return this.elems[index].permString();
    }
    display(index: number): string {
        return this.elems[index].display();
    }
    /**
     * Returns the string of permissions corresponding to all elements less than or equal to the
     * given index (i.e., the full set of permissions that this index grants).
     */
    fullPermString(index: number): string {
        const perms = Arr.flat<string>(this.elems.slice(0, index + 1).map((elem) => elem.data));
        return Str.capitalize(Str.arrayToStringList(perms));
    }
}

/**
 * Given a securityMap and a list of permissions, returns the security map index corresponding to
 * the highest permission (or -1 if no match).
 */
export function getSecurityMapIndex(securityMap: SecurityMap, perms: Base.PermList): number {
    let max = -1;
    if (perms) {
        perms.forEach((p) => {
            max = Math.max(
                max,
                Arr.first(securityMap.elems, (elem) => elem.id === p),
            );
        });
    }
    return max;
}

/**
 * Returns true if the specified permission set contains any actual permissions.
 */
export function hasPerms(perms: Base.PermList): boolean {
    return !!perms && perms.some((p) => p !== "owner");
}

/**
 * Returns the security map to be used when editing homepage folder object permissions. Users are
 * not allowed to grant a higher object permission level than their own level of object permissions.
 * But, if a recipient already has a higher level of object permissions, the selector used to edit
 * those permissions should show the current value and allow the user to revoke those permissions.
 *
 */
export function getFolderObjectSecurityMap(
    folder: HomepageFolder,
    targetIndex?: number,
): SecurityMap {
    const folderSecurityMap = getClassInfo(folder).securityMap;
    let objectSecurityMap = folderSecurityMap;
    if (folder.owner !== User.me && !User.me.hasOrgAdminAccess(Project.CURRENT)) {
        // Start with the permissions the current user has on the folder's objects...
        let maxIndex = getSecurityMapIndex(folderSecurityMap, folder.objectPerms.security);
        if (Is.defined(targetIndex)) {
            // Take the max of the user's permissions and the target recipient's permissions.
            maxIndex = Math.max(maxIndex, targetIndex);
        }
        objectSecurityMap = new SecurityMap(folderSecurityMap.elems.slice(0, maxIndex + 1));
    }
    return objectSecurityMap;
}

/** Default security map used by several shareable objects below. */
export const defaultSecurityMap = new SecurityMap([
    new SecurityInfo(Perm.READ, ["view"]),
    new SecurityInfo(Perm.WRITE, ["edit"]),
    new SecurityInfo(Perm.ADMIN, ["share", "delete"], true),
]);

/** Similar to above but project admins do not have auto-access to the object. */
export const defaultSecurityMapNoProjectAdminAccess = new SecurityMap(
    [
        new SecurityInfo(Perm.READ, ["view"]),
        new SecurityInfo(Perm.WRITE, ["edit"]),
        new SecurityInfo(Perm.ADMIN, ["share", "delete"], true),
    ],
    false,
);

/**
 * Relevant info for a shareable object class. Use the getClassInfo() function below to fetch
 * the appropriate info for a given class/object.
 */
export interface ClassInfo {
    // Should point to the actual object/constructor for the given type.
    objectClass: Base.Class<Base.Object>;
    // Should be specified if access is controlled by a project-level permission.
    projectPermissionName?: string;
    displayName: string;
    // Defined for cases with non-trivial plurals (i.e. can't simply append an "s").
    displayNamePlural?: string;
    icon: string;
    href: (objId: number, obj: Base.Object, msgId: number) => string;
    // Should be specified if and only if the object is a SecuredObject, and needs to match the
    // backend return value of ShareableObject#definedPermissions.
    securityMap?: SecurityMap;
    isStorybuilderObject?: boolean;
    // If specified, overrides the default permission check for sharing (which is an ADMIN perm
    // check with superuser/orgadmin override).
    shareCheck?: (obj: Base.SecuredObject) => boolean;
    // If the class can't be shared from the normal composer dialog, this should explain why.
    invalidAttachmentMsg?: Dom.Content;
}

/**
 * Enum of ShareableObject class names. These values can be passed to getClassInfo. This enum's
 * values must match the enum values in ShareableObject.java.
 */
export enum ShareableObject {
    Argument = "Argument",
    Assignment = "Assignment",
    AssignmentGroup = "AssignmentGroup",
    Binder = "Binder",
    Chronology = "Chronology",
    CodingPreset = "CodingPreset",
    Dataset = "Dataset",
    Deposition = "Deposition",
    Document = "Document",
    HomepageFolder = "HomepageFolder",
    HomepageUpload = "HomepageUpload",
    PredictionModel = "PredictionModel",
    ProcessedSource = "ProcessedSource",
    Production = "Production",
    SavedReviewLayout = "SavedReviewLayout",
    SavedResultsTableView = "SavedResultsTableView",
    SearchResult = "SearchResult",
    SearchTermReport = "SearchTermReport",
    WritingAssistantTemplate = "WritingAssistantTemplate",
}

/**
 * Map containing relevant information for shareable objects.
 */
const classInfo: Map<string, ClassInfo> = new Map(
    Object.entries({
        [ShareableObject.Argument]: {
            objectClass: Argument,
            projectPermissionName: "Storybuilder",
            displayName: "Draft",
            icon: "draft",
            href: (objId: number) => {
                return getArgumentURL(objId);
            },
            isStorybuilderObject: true,
            securityMap: defaultSecurityMap,
        },
        [ShareableObject.Assignment]: {
            objectClass: Assignment,
            displayName: "Assignment",
            icon: "assignments-list",
            href: (objId: number, obj: Base.Object) => {
                const assignment: Assignment = <Assignment>obj;
                let eql: Eql.Any = new Property.Assignment(assignment, "assigned");
                if (assignment.isGrouped) {
                    eql = new Property.SearchHits(
                        {
                            groupType: assignment.groupBy,
                            property: eql,
                        },
                        "and",
                    );
                }
                return resultsURL({
                    eql,
                    sort: assignment.sort,
                    uoId: objId,
                    uoType: Assignment.prototype.className,
                });
            },
            securityMap: new SecurityMap([
                new SecurityInfo(Perm.READ, ["view"]),
                new SecurityInfo(Perm.ADMIN, ["edit", "share", "delete"], true),
            ]),
        },
        [ShareableObject.AssignmentGroup]: {
            objectClass: AssignmentGroup,
            projectPermissionName: "Assignment Groups",
            displayName: "Assignment Group",
            icon: "assignments-list",
            href: (objId: number) => {
                return "assignments.do#id=" + objId;
            },
            securityMap: new SecurityMap([
                new SecurityInfo(Perm.READ, ["view"]),
                new SecurityInfo(Perm.ADMIN, ["allocate", "share", "delete"], true),
            ]),
        },
        [ShareableObject.Binder]: {
            objectClass: Binder,
            displayName: "Binder",
            icon: "binder",
            href: (objId: number, obj: Base.Object) => {
                const binder = <Binder>obj;
                return resultsURL({
                    eql: new Property.Tag(binder, binder.getSearchTerm()).toString(),
                    uoId: objId,
                    uoType: Binder.prototype.className,
                });
            },
            securityMap: defaultSecurityMapNoProjectAdminAccess,
        },
        [ShareableObject.Chronology]: {
            objectClass: Chronology,
            projectPermissionName: "Storybuilder",
            displayName: "Story",
            displayNamePlural: "Stories",
            icon: "story-feather",
            href: (objId: number) => SbPageDefs.buildUrl({ chronId: objId }),
            isStorybuilderObject: true,
            securityMap: new SecurityMap([
                new SecurityInfo(Perm.READ, ["view"]),
                new SecurityInfo(Perm.WRITE, ["edit"]),
                new SecurityInfo(Perm.ADMIN, ["share"], true),
            ]),
        },
        [ShareableObject.CodingPreset]: {
            objectClass: CodingPreset,
            displayName: "Coding Preset",
            icon: "parking",
            href: (objId: number, obj: Base.Object, msgId: number) => {
                return Project.CURRENT.url("messages.do#id=" + msgId);
            },
        },
        [ShareableObject.Dataset]: {
            objectClass: Dataset,
            displayName: "Dataset",
            icon: "upload",
            href: () => "#",
        },
        [ShareableObject.Deposition]: {
            objectClass: Deposition,
            projectPermissionName: "Storybuilder",
            displayName: "Deposition",
            icon: "deposition",
            href: (depoId: number) => {
                return DepoPageDefs.buildDepoUrl({ depoId });
            },
            isStorybuilderObject: true,
            securityMap: defaultSecurityMap,
        },
        [ShareableObject.Document]: {
            objectClass: Document,
            displayName: "Document",
            icon: "file",
            href: (objId: number) => {
                return Util.reviewURL(Project.CURRENT.id, objId);
            },
        },
        [ShareableObject.HomepageFolder]: {
            objectClass: HomepageFolder,
            displayName: "Homepage Folder",
            icon: "folder",
            href: (objId: number) => {
                return "home.do#id=" + objId;
            },
            securityMap: defaultSecurityMapNoProjectAdminAccess,
            invalidAttachmentMsg:
                "Homepage Folders cannot be shared through messaging, so the folder "
                + "will not be attached to this message. To share a Homepage Folder, use the share "
                + "icon on the homepage.",
        },
        [ShareableObject.HomepageUpload]: {
            objectClass: Upload.Homepage,
            displayName: "Upload",
            icon: "upload",
            href: () => "#",
        },
        [ShareableObject.PredictionModel]: {
            objectClass: PredictionModel,
            projectPermissionName: "Prediction Models",
            displayName: "Prediction Model",
            icon: "filter",
            href: (objId: number) => {
                return Project.CURRENT.url("analytics.do#tab=model" + objId);
            },
            securityMap: new SecurityMap([
                new SecurityInfo(Perm.READ, ["view"]),
                new SecurityInfo(Perm.WRITE, ["edit"]),
                new SecurityInfo(Perm.ADMIN, ["share"], true),
            ]),
        },
        [ShareableObject.ProcessedSource]: {
            objectClass: ProcessedSource,
            displayName: "Processed Source",
            icon: "upload",
            href: () => "#",
        },
        [ShareableObject.Production]: {
            objectClass: Production,
            displayName: "Production",
            icon: "send",
            href: () => "#",
        },
        [ShareableObject.SavedReviewLayout]: {
            objectClass: SavedReviewLayout,
            displayName: "layout",
            icon: "layout",
            href: () => "#",
            securityMap: new SecurityMap([new SecurityInfo(Perm.READ, ["view", "share"])], false),
            // You can always share layouts.
            shareCheck: (layout) => true,
        },
        [ShareableObject.SavedResultsTableView]: {
            objectClass: SavedResultsTableView,
            displayName: "Results Table View",
            icon: "layout",
            href: () => "#",
            securityMap: new SecurityMap([new SecurityInfo(Perm.READ, ["view", "share"])], false),
            // You can always share views.
            shareCheck: (_view) => true,
        },
        [ShareableObject.SearchResult]: {
            objectClass: SearchResult,
            displayName: "Search Result",
            icon: "search",
            href: (objId: number, obj: Base.Object, msgId: number) => {
                return resultsURL(undefined, {
                    id: obj.id,
                    norefresh: "true",
                });
            },
            securityMap: defaultSecurityMapNoProjectAdminAccess,
        },
        [ShareableObject.SearchTermReport]: {
            objectClass: SearchTermReport,
            projectPermissionName: "Search Term Reports",
            displayName: "Search Term Report",
            icon: "file-text",
            href: (objId: number) => {
                return Project.CURRENT.url("searchTermReport.do#id=" + objId);
            },
            securityMap: defaultSecurityMap,
        },
        [ShareableObject.WritingAssistantTemplate]: {
            objectClass: WritingAssistantTemplate,
            displayName: "Writing Assistant Template",
            icon: "",
            href: () => "#",
            projectPermissionName: "Storybuilder",
            isStorybuilderObject: true,
            securityMap: new SecurityMap(
                [
                    new SecurityInfo(Perm.READ, ["view"]),
                    new SecurityInfo(Perm.ADMIN, ["edit", "share", "delete"], true),
                ],
                false,
            ),
        },
    }),
);

/**
 * Returns the shareable object class info corresponding to the specified class name or Base.Object,
 * or undefined if there is no such class info for the specified parameter.
 */
export function getClassInfo(objOrClassName: Base.Object | string): ClassInfo {
    return classInfo.get(Is.string(objOrClassName) ? objOrClassName : objOrClassName.className);
}

/**
 * Returns the display name used to describe an attachment class. If the attachment has an entry in
 * the classInfo map, this is just the displayName from that entry. Otherwise, we just do
 * Str.camelToHuman() on the object's className.
 */
export function getClassDisplayName(objOrClassName: Base.Object | string): string {
    const className = Is.string(objOrClassName) ? objOrClassName : objOrClassName.className;
    const classInfo = getClassInfo(className);
    return classInfo ? classInfo.displayName : Str.camelToHuman(className);
}

/**
 * Returns the display name for a ClassInfo object for a ShareableObject. If count is not specified
 * or is not 1, returns the plural form of the display name.
 * @param classInfo ClassInfo object corresponding to a ShareableObject.
 * @param count Used to determine if the returned display name should be plural or singular.
 */
export function getShareableObjectDisplayName(classInfo: ClassInfo, count = 1): string {
    return Str.pluralForm(
        classInfo.displayName,
        count,
        classInfo.displayNamePlural || classInfo.displayName + "s",
    );
}

/**
 * Channel used to publish changes to object security. We need a channel here instead of a simple
 * callback because on the messages page, it's possible for the user to have two message composers
 * open at once if they (a) reply to an existing message, creating an inline composer, and then
 * (b) "Share" the attachment from the object card, creating a dialog composer. Any changes made to
 * permissions from the dialog -- either by sharing the object or editing the existing
 * permissions -- then need to be reflected back in the original already-open inline composer.
 */
export const securityChangeChannel = new PubSubChannel<Base.Object>();

/** Params structure for Selector class. */
export interface SelectorParams {
    parent: HTMLElement;
    securityMap: SecurityMap;
    onChange?: (info: SecurityInfo, index: number) => void;
    default?: SecurityInfo;
}

/**
 * Permissions selector used in the message composer and permission editing dialogs.
 */
export class PermSelector {
    private static readonly TEXT_WIDTH_CACHE = new Map<string, number>();
    private securityMap: SecurityMap;
    private select: ToggledSingleSelect<SecurityInfo>;
    private toDestroy: Util.Destroyable[] = [];
    constructor(params: SelectorParams) {
        this.securityMap = params.securityMap;
        this.select = new ToggledSingleSelect({
            parent: params.parent,
            options: this.securityMap.elems,
            onChange: (info, index) => params.onChange && params.onChange(info, index),
            style: { width: this.getSelectorWidth() + "px" },
            prepRowElement: (elem) => this.prepRowElement(elem),
            default: params.default,
            width: this.getTogglerWidth(),
        });
        this.toDestroy.push(this.select);
    }
    getNode(): HTMLElement {
        return this.select.node;
    }
    setSelected(index: number): void {
        this.select.setCurrentIndex(index, true);
    }
    getSelected(): string {
        return this.securityMap.elems[this.select.getCurrentIndex()].id;
    }
    /**
     * Compute the width of the selector toggler based on the text width for each element.
     */
    private getTogglerWidth() {
        const textWidth = this.securityMap.elems.reduce(
            (w, e) => Math.max(w, PermSelector.getTextWidth(e.display())),
            0,
        );
        // Add extra width for the down arrow used in the toggler.
        return textWidth + 35;
    }
    /**
     * Compute the width of the selector drop-down based on the text width of the full permission
     * string of the highest permission.
     */
    private getSelectorWidth(): number {
        const textWidth = PermSelector.getTextWidth(
            this.securityMap.fullPermString(this.securityMap.elems.length - 1),
        );
        // Scale to 12px (default is 14px), add 12px of padding (6px left and right) and 40px to
        // account for the select icon in the dropdown
        return textWidth * (12 / 14) + 12 + 40;
    }

    /**
     * Returns the rendered width of the given text at 14px font size.
     * These widths are cached to avoid expensive reflows.
     */
    private static getTextWidth(text: string): number {
        const cached = PermSelector.TEXT_WIDTH_CACHE.get(text);
        if (Is.defined(cached)) {
            return cached;
        }
        const computed = PermSelector.computeTextWidth(text);
        PermSelector.TEXT_WIDTH_CACHE.set(text, computed);
        return computed;
    }
    /**
     * Computes the size of the given text if rendered at 14px.
     */
    private static computeTextWidth(text: string): number {
        const node = Dom.create(
            "div",
            { class: "shareable-object-perm-selector-toggler-geom-node" },
            document.body,
        );
        node.innerText = text;
        const width = node.getBoundingClientRect().width;
        Dom.destroy(node);
        return width;
    }
    /** Create the selector row element for the given entry. */
    private prepRowElement(e: SecurityInfo): BaseSelect.Row {
        const index = this.securityMap.elems.indexOf(e);
        return {
            node: Dom.div(
                {
                    class: "shareable-object-perm-selector-element table-row action description",
                    style: { position: "relative" },
                },
                [
                    Dom.div(
                        {
                            class: "shareable-object-perm-selector-element__perm-string",
                        },
                        this.securityMap.display(index),
                    ),
                    Dom.div(
                        {
                            class: "shareable-object-perm-selector-element__full-perm-string",
                        },
                        this.securityMap.fullPermString(index),
                    ),
                ],
            ),
            onDestroy: [],
        };
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

/** Object security retrieved from the backend by shareableObject/getSecurity.rest. */
export interface ObjectSecurity {
    // Map of (directly-shared) SID-->perms
    sharedSecurity: Base.ACL;
    // Map of folder sharing SID-->perms
    sharedFolderSecurity?: Base.ACL;
    // If the object is controller by project-level permissions, the list of users and groups who
    // have permissions to be shared the object. (If not specified, all users/groups can receive.)
    shareableUsers?: User.Id[];
    shareableGroups?: User.GroupId[];
    // Homepage folders return the object permissions on the folder.
    folderObjectSecurity?: Base.ACL;
}

export type ShareableUsersAndGroups = Required<
    Pick<ObjectSecurity, "shareableUsers" | "shareableGroups">
>;

export function getSecurity(obj: Base.Object): Promise<ObjectSecurity> {
    return Rest.get("shareableObject/getSecurity.rest", {
        objectClass: obj.className,
        objectId: obj.id,
    });
}

export function getShareableUserGroupsAndUsers(obj: Base.Object): Promise<ShareableUsersAndGroups> {
    return Rest.get("shareableObject/getShareableUsersAndGroups.rest", {
        objectClass: obj.className,
    });
}

// Permission info serialized from the backend.
export interface RecipientPermInfo {
    className: string;
    id: number;
    chronId?: number; // for depos and non-orphaned drafts
    display: string;
    perms: Base.PermList;
}

export function getRecipientSecurity(
    sid: string,
): Promise<{ [permSet: string]: RecipientPermInfo[] }> {
    return Rest.get("shareableObject/getRecipientSecurity.rest", { sid });
}

export function setPerms(
    className: string,
    id: string | number,
    sid: string,
    perm: string,
): Promise<void> {
    return Rest.post("shareableObject/setPerms.rest", {
        objectClass: className,
        objectId: id,
        sid,
        perm,
    });
}

export function revokePerms(className: string, id: string | number, sid: string): Promise<void> {
    return Rest.post("shareableObject/setPerms.rest", {
        objectClass: className,
        objectId: id,
        sid,
    });
}

export function setFolderObjectPerms(
    folder: HomepageFolder.Id,
    sid: string,
    perm: string,
): Promise<void> {
    return Rest.post("setFolderObjectPerms.rest", { folder, sid, perm });
}

export function revokeProjectPermSetPermissions(
    sids: string[],
    permSetIds: string[],
): Promise<void> {
    return Rest.post("shareableObject/revokePermSetPermissions.rest", { sids, permSetIds });
}
